.archk-template-docs-wrapper {
    .card {
        border-radius: 0;
    }

    .archk-template-docs-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }
}

.archk-template-docs {

    width: 720px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 30px;

  

    .doc-progress {
        z-index: 200;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 6px 8px;
        // border-top: solid 1px #aaa;
    }

    @keyframes confirmationArrow {
        0% { top: -40px; color: $green; }
        25% { top: -50px; color: $orange;}
        50% { top: -40px; color: $green; }
        75% { top: -50px; color: $orange;}
        100% { top: -40px; color: $green; }
    }

    .confirmation-arrow {
        color: $orange;
        position: absolute;
        left: calc(50% - 12px);
        top: -40px;
        z-index: 2;
        font-size: 30px;
        animation: confirmationArrow 3s infinite;
        text-shadow: 1px 1px 1px black;

    }

    .page-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
    }

    .pdf-marker {

        position: absolute;
        z-index: 3;
        border: solid 2px darken($yellow, 5%);
        background: rgba(255, 214, 0, .3);;
        width: 100%;
        overflow: hidden; 
        cursor: pointer;

        &.pdf-marker-filled {
            background: #a7c81b94 !important;
            border-color: $green !important;
            color: $white;
        }

        &.pdf-marker-date {
            background-color: rgba(255,255,255,0) !important;
            color: black !important;
            border: none !important;
            box-shadow: none !important;
            overflow: visible;

            .marker-name {
                overflow: visible;
            }
        }


        &.is-focused {
            z-index: 900;
            // color: $white;
            &.pdf-marker-date  {
                color: lighten($yellow, 15%) !important;
            }
        }

        .marker-name {
            position: absolute;
            left: 0;
            width: 100%;
            font-weight: bold;
            font-size: 12px;
            white-space: pre;
            bottom: -3px;
        }

        &:hover {
            .toolbar {
                display: block;
            }
        }

        .toolbar {
            display: none;
            height: 30px;

            width: 150px;
            position: absolute;
            top: -33px;

            ul {

                li {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    padding-top: 4px;
                    cursor: pointer;
                    color: $gray-300;

                    &:hover {
                        background: $gray-600
                    }

                    &.active {
                        color: $green;
                    }

                }

            }

        }

        .identifier {

            i {

                position: absolute;
                bottom: -38px;
                left: calc(50% - 20px);
                width: 35px;
                height: 35px;
                background: $green;
                padding: 8px;
                color: white;
                border-radius: 3px;
                border: solid 2px white;
                box-shadow: 2px 2px 2px 2px black;

                &.party-0 {
                    background-color: $primary;   
                }
                &.party-1 {
                    background: $green;
                }
                &.party-2 {
                    background: $tealOriginal;
                }
                &.party-3 {
                    background: $red;
                }
                &.party-4 {
                    background: $purple;
                }

            }

        }

        .text-input {

            display: none;
            height: 30px;
            width: 150px;
            position: absolute;
            top: -78px;
        }


    }

}

.modal-add-signature {

    .signature-body {
        padding: 0 !important;

        .form-group {
            margin-bottom: 0;
        }

    }
    
}

@media(max-width: 720px) {

    .modal-add-signature {
        max-width: 98% !important;
    }
}

.archk-template-docs-container.container {
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow: hidden;
    margin-top: 60px;
}